import React, { useState, useMemo, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';

import AutocompleteDropDown from 'components/uiLibrary/FormInputs/AutocompleteDropDown';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Loader from 'components/uiLibrary/Loader';

import { TP } from 'constants/index';

import { useQuery } from 'utils/react-query';

import { useTranslation } from 'src/i18n';

const DEFAULT_QUERY_VALUE = () => ({
  queryFn: () => {},
  queryKey: ['DEFAULT_QUERY_VALUE'],
  enabled: false,
});

const EMPTY_OBJECT = {};

const AutoCompleteDropdown = ({
  className,
  label,
  value,
  onChange,
  searchQuery = DEFAULT_QUERY_VALUE,
  aggregationQuery,
  disablePortal,
  onBlur,
  queryKey = 'query',
  prefetchSearchQuery = false,
  fullWidth = false,
  searchQueryFilters = EMPTY_OBJECT,
  isDisabled = false,
  disableSearchOnInputChange = false,
  textFieldProps = {},
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const [query, setQuery] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const { data: suggestions, refetch: fetchSuggestions, isLoading: isLoadingSuggestions, isFetched } = useQuery(
    searchQuery({
      filters: {
        ...(!disableSearchOnInputChange && { [queryKey]: query }),
        ...searchQueryFilters,
      },
      queryConfig: { enabled: false, select: response => response?.data },
    }),
  );

  const debouncedFetchSuggestions = useMemo(() => debounce(fetchSuggestions, 300), [fetchSuggestions]);

  useEffect(() => {
    if (!disableSearchOnInputChange && (query || Object.keys(searchQueryFilters).length > 0)) {
      debouncedFetchSuggestions();
    }
  }, [disableSearchOnInputChange, debouncedFetchSuggestions, query, searchQueryFilters]);

  const optionsQuery = aggregationQuery || searchQuery;

  const { data: aggregatedOptions, isLoading: isLoadingAggregatedOptions } = useQuery(
    optionsQuery({
      queryConfig: {
        select: response => response?.data,
        keepPreviousData: true,
        enabled: isExpanded,
      },
    }),
  );

  const options = useMemo(() => {
    const baseOptions = query || Object.keys(searchQueryFilters).length > 0 ? suggestions : aggregatedOptions;

    if (value && !baseOptions?.some(option => option.id === value.id || option.slug === value.slug)) {
      return [value, ...(baseOptions || [])];
    }

    return baseOptions || [];
  }, [value, suggestions, aggregatedOptions, query, searchQueryFilters]);

  const onChangeHandler = useCallback(
    (e, selectedValue, reason) => {
      if (onChange && reason !== 'clear') {
        setQuery(null);
        onChange(e, selectedValue);
      }
    },
    [onChange],
  );

  const onInputChange = (e, inputValue, reason) => {
    if (reason === 'input') {
      setQuery(inputValue);
    }
  };

  const isLoadingOptions = useMemo(() => {
    if (!isExpanded) {
      return false;
    }

    return query ? isLoadingSuggestions : isLoadingAggregatedOptions;
  }, [isExpanded, isLoadingAggregatedOptions, isLoadingSuggestions, query]);

  const endAdornment = useMemo(() => {
    if (isLoadingOptions) {
      return <Loader small color="#0067c5" />;
    }

    if (value) {
      return (
        <SpriteIcon
          icon="close"
          size={20}
          onClick={e => {
            e.stopPropagation();
            if (!isDisabled) {
              onChangeHandler(e);
            }
          }}
        />
      );
    }

    return <SpriteIcon icon="expand_more" size={20} />;
  }, [isLoadingOptions, onChangeHandler, value]);

  return (
    <AutocompleteDropDown
      className={className}
      options={options}
      value={value || null}
      isOptionEqualToValue={(option, selectedValue) => selectedValue && selectedValue?.id === option?.id}
      onChange={onChangeHandler}
      label={label}
      disabled={isDisabled}
      onBlur={onBlur}
      InputProps={{
        endAdornment,
      }}
      filterOptions={(optionsList, state) => {
        const inputText = state.inputValue?.trim().toLowerCase();

        if (value?.name?.toLowerCase() === inputText) {
          return optionsList;
        }

        return optionsList?.filter(option => option.name?.toLowerCase()?.includes(inputText));
      }}
      onInputChange={onInputChange}
      getOptionLabel={option => option?.name}
      InputLabelProps={{ shrink: true }}
      textFieldProps={{ placeholder: t(`${TP}.FN_SELECT`), ...textFieldProps }}
      inputValue={query ?? (value?.name || '')}
      onClick={() => {
        if (!isFetched && prefetchSearchQuery) {
          debouncedFetchSuggestions();
        }

        setIsExpanded(true);
      }}
      open={isExpanded}
      onClose={() => {
        setIsExpanded(false);
        setQuery(null);
      }}
      freeSolo={false}
      isLoading={isLoadingOptions}
      fullWidth={fullWidth}
      blurOnSelect
      overrideOnInputChange
      disablePortal={disablePortal}
    />
  );
};

export default AutoCompleteDropdown;
